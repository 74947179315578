<template>
  <div class="mAbout">
    <div class="mAbout-top">
      <img  v-lazy="top" alt="">
    </div>
    <div class="mAbout-center">
      <div class="mAbout-center-top">
        <img v-lazy="text" alt="" srcset="">
      </div>
      <div class="mAbout-center-center">
        <div class="mAbout-center-center-left">
          <!-- 地图 -->
          <el-amap vid="amapContainer" :amap-manager="amapManager" class="amap-demo"  :events="events">
            <el-amap-marker :position="centericon" />
          </el-amap>

        </div>
        <div class="mAbout-center-center-right">
          <p>邮箱：{{ detailList.email }}</p>
          <p>服务时间：{{ detailList.serverTime }}</p>
          <p>地址：{{ detailList.address }}</p>
          <div class="aboutTop-bottom-right-list">
              <p>微信咨询：</p>
              <img :src="detailList.wxUrl" alt="">
            </div>

        </div>

      </div>
      <!-- <div class="mAbout-center-bottom">
        <div class="mAbout-center-bottom-item" v-for="(item,index) in list" :key="index">
          <div class="mAbout-center-bottom-item-img">
            <img :src="item.img" alt="">
          </div>
          <p>{{item.title}}</p>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
 import {contactInfo} from '@/api/index.js'
import { AMapManager, lazyAMapApiLoaderInstance } from 'vue-amap'
const amapManager = new AMapManager()
export default {
  data() {
    return {
      detailList:{},
      top:require('../../assets/mi/about/top.png'),
      text:require('../../assets/mi/about/text.png'),
      list: [
        { img: require('../../assets/home/code.png'), title: '关注微信公众号' },
        { img: require('../../assets/home/code.png'), title: '微信小程序' },
        { img: require('../../assets/home/code.png'), title: 'ios下载' },
        { img: require('../../assets/home/code.png'), title: '安卓下载' },
      ],
      amapManager,
      events: {
      },
      markerList: [
        { marker: [118.052156,24.60758], name: '软件园三期C03栋' },
      ],
    }
  },
  mounted() {
    this.contactInfo()
    lazyAMapApiLoaderInstance.load().then(() => {

      this.map = new window.AMap.Map('amapContainer', {
        center: [118.052156,24.60758], //地图显示的中心位置
        zoom: 17, //地图缩放比例
      })
      this.map.addControl(new window.AMap.ToolBar({
        // 简易缩放模式，默认为 false
        liteStyle: true
      }));
      //地图标记点方法
      this.createMark()
    })
  },
  methods: {
    contactInfo(){
        contactInfo().then(res=>{
          this.detailList=res.data
        })
      },
    createMark() {
    //   const icon = new window.AMap.Icon({
    //     size: new window.AMap.Size(24, 24),
    //     image: this.iconPoint,
    //     imageSize: new window.AMap.Size(24, 24)
    //   })
    //   var labelOffset = new window.AMap.Pixel(0, -5);
      this.markerList.forEach((item) => {
        // const labelContent = "<div class='labelContent'>" + item.name + "</div>"
        const marker = new window.AMap.Marker({
          position: item.marker,
        //   icon: icon,
          anchor: 'center', //设置锚点
          offset: new window.AMap.Pixel(0, 0), //设置偏移量
        //   label: {
        //     direction: 'top',
        //     content: labelContent,
        //     offset: labelOffset,
        //   }
        })
        // this.map.add(marker)
        marker.setMap(this.map)
      })
    },
    eventsFun() {
      const self = this
      return {
        // 地图加载完成时执行的方法
        complete() {
          self.createMark()
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>

/deep/ .amap-zoomcontrol{
    display: none;
}
.mAbout {
  .mAbout-top {
    font-size: 0px;
    img {
      width: 100%;
      height: 550px;
    }
  }
  .mAbout-center {
    width: 100%;
    height: 367px;
    background-image: url("../../assets/mi/about/bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    /deep/ .amap-layers{
      border-radius: 5px 0px 0px 5px;

    }
    .mAbout-center-top {
    margin-top: 57px;
      margin-bottom: 25px;
      img {
        width: 336px;
        height: 56.5px;
      }
    }
    .mAbout-center-center {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      box-sizing: border-box;
      height: 160px;
      display: flex;
      .mAbout-center-center-left {
        height: 160px;
        width: 150px;
        border-radius: 5px 0px 0px 5px;
      }
      .mAbout-center-center-right {
      border-radius: 0px 5px 5px 0px;
        flex: 1;
        height: 160px;
        background-color: #fff;
        padding: 0px 0px 0px 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          font-size: 12px;
          zoom: 0.83;
          color: #333333;
          margin-bottom: 15px;
        }
        p:last-child{
          margin-bottom: 0px;
        }
        .aboutTop-bottom-right-list{
          display: flex;
          align-items: center;
          img{
            width: 70px;
            height: 70px;
            border-radius: 6px;
            // background-color: #EEEEEE;
          }
        }
      }
    }
    .mAbout-center-bottom {
      display: flex;
      margin-top: 48px;
      width: 100%;
      box-sizing: border-box;
      justify-content: center;
      .mAbout-center-bottom-item :last-child{
        margin-right: 0px;
      }
      .mAbout-center-bottom-item {
        display: flex;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        .mAbout-center-bottom-item-img {
          background-color: #fff;
          width: 75px;
          height: 75px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 68px;
            height: 68px;
          }
        }
        p {
          font-size: 10px;
          color: #333333;
          margin-top: 7px;
          letter-spacing: 1px;
        }
      }
    }
  }
}
</style>